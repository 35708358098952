import * as Sentry from '@sentry/remix'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { posthog } from 'posthog-js'

Sentry.init({
  dsn: 'https://7ec88ff80447a6efef9ee9082f50318d@o4507042778251264.ingest.us.sentry.io/4507042783297536',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
})

function PosthogInit() {
  useEffect(() => {
    // This is a public, client-side key, so it's safe to expose it.
    posthog.init('phc_4xLRS6KcTxrjxIdCpGVaohq9qUJdVwTx2At3wyeQa0m', {
      api_host: 'https://app.posthog.com',
    })
  }, [])

  return null
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PosthogInit />
    </StrictMode>,
  )
})

import { Link } from '@remix-run/react'
import { Button } from '@/components/ui/button'

function Header() {
  return (
    <header className="py-4">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <nav className="relative z-50 flex justify-between">
          <div className="grid grid-cols-1 gap-y-4 sm:gap-x-12 lg:grid-cols-[140px_1fr]">
            <Link to="/" aria-label="Home">
              <img src="/joyst_logo.png" alt="Joyst" width="140" height="140" />
            </Link>
            <div className="flex sm:gap-x-2">
              <Link className="px-2 py-2" to="/banking">
                For Banking
              </Link>
              <Link className="px-2 py-2" to="/real-estate">
                For Real Estate
              </Link>
              <Link className="px-2 py-2" to="/about">
                About Us
              </Link>
              <Link className="block px-2 py-2 lg:hidden" to="/auth/login">
                Sign in
              </Link>
            </div>
          </div>
          <div className="hidden items-center gap-5 lg:flex lg:gap-8">
            <Link to="/auth/login">Sign in</Link>

            <Link to="#contact-us">
              <Button variant="brand">
                <span>Learn More</span>
              </Button>
            </Link>
            <div className="-mr-1 md:hidden">{/* <MobileNavigation /> */}</div>
          </div>
        </nav>
      </div>
    </header>
  )
}

function Footer() {
  return (
    <footer className="bg-slate-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex w-full items-center justify-between">
          <div className="py-16">
            <img src="/joyst_logo.png" alt="Joyst" width="140" height="140" />
          </div>
          <div className="flex gap-8">
            <div className="flex flex-col">
              <a href="/auth/login">Sign In</a>
            </div>
            <div className="flex flex-col">
              <a href="#contact-us">Contact Us</a>
            </div>
            <div className="flex flex-col">
              <a href="/about">About Us</a>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} Joyst. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default function MarketingLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div className="h-full scroll-smooth bg-white antialiased">
      <div className="flex h-full flex-col">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  )
}

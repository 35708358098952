export default function AuthLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div className="min-h-full bg-gradient-to-br from-brand-600 to-brand-900">
      {children}
    </div>
  )
}

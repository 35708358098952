import { type ClassValue, clsx } from 'clsx'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { JoystUser } from './session.server'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toStringSafe(val: number | undefined): string {
  return val ? val.toString() : ''
}

export function getErrorMessage(error: unknown) {
  if (typeof error === 'string') return error
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message
  }
  console.error('Unable to get error message for error', error)
  return 'Unknown Error'
}

export function fetchApi(
  request: Request,
  path: string,
  init: RequestInit = {},
  contentType: string | boolean = 'application/json',
): Promise<Response> {
  const backend_url = process.env.PUBLIC_BACKEND_URL;
  const headers = { Accept: 'application/json' };

  if (contentType !== false) {
    headers['Content-Type'] = contentType === true ? 'application/json' : contentType;
  }

  return fetch(`${backend_url}${path}`, {
    // @ts-ignore
    headers: {
      cookie: request.headers?.get('cookie') ?? undefined,
      ...headers,
      ...init?.headers,
    },
    ...init,
  });
}

type ShowFieldErrorsProps = {
  field_errors?: { [k: string]: string }
}
export function showFieldErrors(
  responseJson: ShowFieldErrorsProps | undefined,
) {
  return useEffect(() => {
    if (!responseJson) return
    if (responseJson.field_errors) {
      window.dispatchEvent(
        new CustomEvent('field-errors', { detail: responseJson.field_errors }),
      )
    }
  }, [responseJson])
}

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
})

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const combined = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value)
    }
  }
  return combined
}

export const fmtMonths = (
  months?: number | null,
  short: boolean = false,
): string => {
  if (!months) return ''
  let y = months / 12
  let m = months % 12
  let r = ''

  if (months > 18) {
    y = Math.ceil(y)
    r += y
    r += short ? 'y' : y === 1 ? ' year' : ' years'

    return r
  } else {
    r += `${m}`
    r += short ? 'm' : m === 1 ? ' month' : ' months'
    return r
  }
}

export function primaryProperty(user: JoystUser) {
  return (
    user.properties.find(p => p.id == user.current_property_id) ||
    user.properties[0]
  )
}
